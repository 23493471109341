import React from "react";
import Teachers from "../../components/NewUi/horizontalAnimation/Teachers";
import Heading from "../../components/NewUi/heading/Heading";
import Container from "../../components/ui/Container";
import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
import Star from "../../assets/Vip-imagery/star.png";
import VariantButton from "../../components/ui/Buttons/VariantButton";
import ConstructionDecore from "../../components/ui/ConstructionDecore";
import { SectionHeader } from "../../components/ui/SectionHeader";
import { Link } from "react-router-dom";
const TeacherSection = () => {
    const token = auth.getToken();

    return (
        <section id="mentors" className="min-h-[80vh] relative my-20 overflow-hidden">
            <Container className="h-full  z-10 pt-32 md:pt-10 pb-20 md:pb-0  flex flex-col items-center justify-center space-y-5">
                <div className="flex relative flex-col items-center  space-y-15">
                    <div className="flex flex-col w-full items-center justify-center h-fit gap-20">
                        <div className="pt-10">
                            <SectionHeader
                                title="خبراؤنا"
                                SpecialFont
                                className="text-4xl md:text-6xl xl:text-7xl clr-text-primary smooth"
                                position="right-0 -top-0 sm:-top-10 -z-10 md:left-0"
                            />
                        </div>
                        {/* <div className="flex flex-col md:flex-row justify-between items-center w-full md:space-x-reverse space-y-10 md:space-y-0 md:space-x-20 space-x-0 ">
                            <p className="text-center md:text-right w-full text-lg md:text-xl md:max-w-[450px]">
                                ديـ ديـ جابتلك كل الخبراء في المواد التعليمية اونلاين عشان يساعدوك
                                ويجاوبوك على كل اسئلتك وكمان هتبقي حريف في حياتك التعليمية.
                            </p>
                            <div className="btn-holder flex-1 ">
                                <Link
                                    className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-didiBlue before:border-t-didiBlue after:border-r-didiBlue after:border-b-didiBlue "
                                    to={`${token ? "/me/user/courses" : "/register"}`}
                                >
                                    <div className="bg-didiBlue smooth px-7 py-4 flex-center-both gap-4">
                                        <span className="text-link text-didiRed-50 text-lg">
                                            يلا مع ديـ ديـ
                                        </span>
                                    </div>
                                </Link>
                            </div>
                        </div> */}
                    </div>
                    {/* <div className="-ml-20">
                        <ConstructionDecore className={""} isRight={false} />
                    </div> */}
                </div>
                <div className="">
                    <Teachers />
                </div>
                <div className="btn-holder flex-1 ">
                    <Link
                        className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-didiBlue before:border-t-didiBlue after:border-r-didiBlue after:border-b-didiBlue "
                        to={`${token ? "/mentors" : "/register"}`}
                    >
                        <div className="bg-didiBlue smooth px-7 py-4 flex-center-both gap-4">
                            <span className="text-link text-didiRed-50 text-lg">مشاهدة المزيد</span>
                        </div>
                    </Link>
                </div>
            </Container>
        </section>
    );
};

export default TeacherSection;
