import "./scroll.css";
import Content from "./Content";
import ParallaxImage from "./Parallax";
import pattern from "../../../assets/didi-imagery/pattern.svg";

const Teachers = () => {
    return (
        <div className="TeacherScroll ">
            <div className="firstLine relative overflow-hidden bg-[#F3F1F1] dark:bg-slate-800 smooth max-w-">
                <div
                    className="right-0 top-0 bottom-0 z-0 h-full w-full absolute opacity-20 dark:opacity-50 smooth"
                    style={{
                        backgroundImage: "url(" + pattern + ")",
                        backgroundSize: "contain",
                        backgroundPosition: "center center",
                        backgroundRepeat: "repeat-both",
                    }}
                ></div>
                <ParallaxImage baseVelocity={-5}>
                    <Content />
                </ParallaxImage>
            </div>
        </div>
    );
};

export default Teachers;
