import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";

import FooterLink from "./components/FooterLink";
import { Facebook, Youtube, Tiktok, instagram } from "./Icons";
import { useLocation } from "react-router-dom";
import { adminPath, teachersubadminPath } from "../../services/defaultSettings";
import CenterIcon from "../../components/ui/CenterIcon";
import Logo from "../../assets/didi-imagery/logoFooter.png";
const Footer = () => {
    const location = useLocation();

    const [className, setClassName] = useState("");

    useEffect(() => {
        if (
            location.pathname.includes(adminPath) ||
            location.pathname.includes(teachersubadminPath)
        ) {
            setClassName(" !hidden");
        } else {
            setClassName("");
        }
    }, [location.pathname]);
    return (
        <div
            className={`footer bg-[#BFBEBE] dark:bg-slate-800 smooth relative py-20 flex-center-both flex-col space-y-6 w-full ${className} gap-6`}
        >
            <div className="w-[200px] md:w-[260px] h-auto flex justify-center">
                <img src={Logo} alt="didiFooterLogo" />
            </div>
            <div className="flex-center-both clr-text-primary space-x-2 space-x-reverse z-10 text-2xl smooth rounded-md px-5 py-4 saturate-50">
                <p className="">متخصصة للطلاب الساعين للتفوق</p>
            </div>
            {/* <div className="relative w-screen">
                <div className="relative z-10 flex items-center justify-center space-x-5 space-x-reverse gap-4 ">
                    <FooterLink link="" icon={Facebook} />
                    <FooterLink link="" icon={instagram} />
                    <FooterLink link="" icon={Tiktok} />
                    <FooterLink link="" icon={Youtube} />
                </div>
            </div> */}
            {/* <div className="h-1 bg-slate-800 rounded-md w-2/3 sm:w-1/3 "></div> */}
            <div className="Footer__wrapper-button mt-9 lg:mt-0">
                <div className="en textx text-center font-com space-x-2 opacity-90 px-5 flex flex-wrap flex-center-both">
                    <span className="font-w-bold space-x-1">
                        <span className="text-black dark:text-didiBlue smooth">&#60;</span>
                        <span className="text-black dark:text-didiBlue smooth">Developed By</span>
                        <span className="text-black dark:text-didiBlue smooth">&#62;</span>
                    </span>
                    <span>
                        <a
                            href="https://www.facebook.com/Om4rS4Ieh/"
                            className="text-didiBlue font-w-bold  hover-shadow smooth px-2 py-2 rounded-md"
                        >
                            Omar
                        </a>
                        <span>,</span>
                        <a
                            href="https://www.facebook.com/emad.sharf.16"
                            className="text-didiBlue font-bold hover-shadow smooth px-3 py-1 rounded-md"
                        >
                            Emad
                        </a>
                    </span>
                    <span className="font-w-bold space-x-1">
                        <span className="text-black dark:text-didiBlue smooth">&#60;</span>
                        <span className="text-black dark:text-didiBlue smooth">
                            All Copy Rights Reserved @{new Date().getFullYear()}
                        </span>
                        <span className="text-black dark:text-didiBlue smooth">&#62;</span>
                    </span>
                </div>
            </div>
            {/* <div className="text"></div> */}
        </div>
    );
};

export default Footer;
