import React from "react";

// import logo from "../assets/navbar/logo.png";
//Router component
import NavbarLink from "../layout/navbar/components/NavbarLink";
//menu
import DisclosureLink from "../layout/navbar/components/DisclosureLink";
import CenterIcon from "../components/ui/CenterIcon";

import logo from "../assets/didi-imagery/logoLight.png";
import logoDark from "../assets/didi-imagery/logoDark.png";
import { ReactComponent as Moon } from "../assets/didi-imagery/moon.svg";

// logo png is here
export const navLogoComponent = (darkmode) => (
    <>
        {/* <img
            className="h-12 w-auto block lg:hidden"
            src={logo}
            alt="Workflow"
        /> */}
        {!darkmode ? (
            <img className="h-8 sm:h-10 w-auto md:mr-0 mr-6" src={logo} alt="Workflow" />
        ) : (
            <img className="h-8 sm:h-10 w-auto md:mr-0 mr-6" src={logoDark} alt="Workflow" />
        )}
    </>
);
export const navbarClassName = (isNavbarHidden, isRTL, scrolling) =>
    // color of nav background
    ` ${!isRTL ? "en" : ""} ${scrolling && "bg-primary-container"}`;

// here are Buttons
export const NavbarRegisterComponent = () => (
    <NavbarLink
        defualtHover=""
        to="/register"
        className="bg-[#D9E4F2] border-didiBlue rounded-none hover-shadow dark:bg-[#284971] clr-white border group"
    >
        <span className="flex-center-both font-h2">
            <Moon className="w-auto h-7 fill-[#284971] dark:fill-[#D9E4F2] smooth" />
        </span>
        <span className="flex-center-both text-[#284971] smooth dark:text-[#D9E4F2]">
            انشئ حسابك
        </span>
    </NavbarLink>
);

export const NavbarLoginComponent = ({ isRTL }) => (
    <NavbarLink
        to="/login"
        className="bg-didiBlue border clr-white border-[#284971] rounded-none hover-shadow"
        defualtHover="hover:dark:border-opacity-100 group"
    >
        <CenterIcon
            icon="majesticons:login-half-circle"
            className=" font-h2 group-hover:rotate-[-360deg] smooth"
            nY="0"
        />
        <span
            className={`flex-center-both  space-x-1 text-sm space-x-reverse ${
                !isRTL ? "flex-row-reverse" : ""
            }`}
        >
            سجل دخولك
        </span>
    </NavbarLink>
);

export const DisclosureLoginComponent = ({ isRTL }) => (
    <DisclosureLink to="/login">
        <span className="text-yellow-500 flex-center-both font-h2">
            <CenterIcon icon={"ic:twotone-accessibility-new"} />
        </span>
        <span className="flex-center-both">سجل الدخول لـ حسابك</span>
    </DisclosureLink>
);
export const DisclosureRegisterComponent = () => (
    <DisclosureLink to="/register">
        <span className="flex-center-both font-h2 smooth text-sky-400 group-hover:text-sky-500 dark:group-hover:text-sky-500">
            <CenterIcon icon="material-symbols:tab-new-right-outline-rounded" />
        </span>
        {/* <UserAddIcon className="h-6 w-6 text-yellow-300" /> */}
        <span className="flex-center-both">اعمل حساب جديد !</span>
    </DisclosureLink>
);

export const domainName = "mahmoud-magdy.com";
