import React from "react";
import LoadingIcon from "../../ui/LoadingIcon";
import { Link } from "react-router-dom";
import "./Btns.css";
const VariantButton = ({
    children,
    color = "FrenchRed",
    borderColor = "border-teal-500",
    textColor = "text-white",
    className = "",
    hoverEffect = "hover:bg-teal-700",
    type,
    isLoading = false,
    isDisabled = false,
    onClick = null,
    element = "Link",
    reversed = false,
    ButtonType = "offset",
    icon = null,
    ...props
}) => {
    if (!className.includes("bg")) {
        if (color === "FrenchRed") {
            className += ` bg-FrenchRed-500  dark:bg-FrenchRed-500 outline-FrenchRed-500 dark:border-FrenchRed-600 hover:bg-opacity-0 dark:hover:bg-opacity-0 hover:text-FrenchRed-500 dark:hover:text-FrenchRed-500 clr-white `;
        } else if (color === "rose") {
            if (reversed) {
                className += ` bg-rose-500 border-rose-500 dark:bg-rose-500 dark:border-rose-500 hover:bg-opacity-100 dark:hover:bg-opacity-100 dark:bg-opacity-0 bg-opacity-0 text-rose-500 dark:hover:text-rose-500 hover:text-slate-50 dark:hover:text-slate-50`;
            } else {
                className += ` bg-rose-500 border-rose-500 dark:bg-rose-500 dark:border-rose-500 hover:bg-opacity-0 dark:hover:bg-opacity-0 dark:bg-opacity-100 bg-opacity-100 hover:text-rose-500 dark:hover:text-rose-500 clr-white `;
            }
        } else if (color === "Lemon") {
            if (reversed) {
                className += ` bg-Lemon-200 border-Lemon-950 dark:bg-Lemon-700 dark:border-rose-500 `;
            } else {
                className += ` bg-Lemon-200 border-Lemon-950 border-solid border-[.5px] dark:bg-Lemon-500 text-Lemon-950`;
            }
        }
    }
    if (ButtonType) {
        if (ButtonType == "offset") {
            className += `outline outline-offset-4 rounded-xl  hover:outline-offset-0`;
        } else if (ButtonType == "Retro") {
            className += `border-none `;
        }
    }
    return (
        <>
            {element === "Link" && (
                <Link
                    onClick={onClick}
                    type={type}
                    className={`relative inline-flex  items-center justify-center px-6 py-3 ${hoverEffect}  ${
                        isLoading ? "opacity-50 cursor-not-allowed" : ""
                    } ${isDisabled ? "opacity-50 cursor-not-allowed" : ""} smooth ${className} }`}
                    disabled={isDisabled || isLoading ? true : false}
                    {...props}
                >
                    {isLoading ? (
                        <LoadingIcon />
                    ) : (
                        <>
                            <>{children}</>
                            {icon && <img src={icon} alt="Icon" className="ml-2 w-5 h-5" />}
                        </>
                    )}
                </Link>
            )}
            {element === "button" && (
                <button
                    onClick={onClick}
                    type={type}
                    className={`${isLoading ? "loading" : ""} ${
                        isLoading || isDisabled ? "opacity-50" : ""
                    } border-2 smooth relative inline-flex items-center justify-center px-6 py-3 ${className} ${hoverEffect}`}
                    disabled={isDisabled || isLoading ? true : false}
                    {...props}
                >
                    {isLoading ? <LoadingIcon /> : children}
                </button>
            )}
        </>
    );
};
export default VariantButton;
