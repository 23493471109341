import "./teacher.css";
// import Content from "./Content";
import ParallaxTeacher from "./Parallax";
import React, { useEffect, useRef, useState } from "react";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import { description as FormatDesc } from "../../../utils/ar";

// import LoadingIcon from "../../../components/ui/LoadingIcon";
import { Link } from "react-router-dom";
import { baseURL } from "../../../config";
import LoadingIcon from "../../ui/LoadingIcon";
import Arrow from "../../../assets/didi-imagery/arrow.png";
import pattern from "../../../assets/didi-imagery/pattern.svg";
export default function TeacherLine({ department_id = 0, subjectId = 0, isSubject = false }) {
    const [moveX, setMoveX] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    const cardWidth = 300;

    const handleCarouselLeft = () => {
        setMoveX((prevMoveX) => {
            const newMoveX = prevMoveX - cardWidth;
            return newMoveX < 0 ? (teachers.length - 1) * cardWidth : newMoveX;
        });
    };

    const handleCarouselRight = () => {
        setMoveX((prevMoveX) => {
            const newMoveX = prevMoveX + cardWidth;
            return newMoveX >= teachers.length * cardWidth ? 0 : newMoveX;
        });
    };

    // console.log(moveX);

    const handleHover = () => {
        setIsHovering(!isHovering);
    };

    const [teachers, setTeachers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getTeachers = async () => {
        setIsLoading(true);
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);

        let result;
        // console.log(subjectId);
        if (isSubject) {
            result = await http.get(`api/subjects/${subjectId}/teachers`, config);
            // console.log(subjectId);
        } else {
            result = await http.get(`api/departments/${department_id}/teachers`, config);
        }

        const { data } = result;

        setTeachers([]);
        setTeachers(data);
        setIsLoading(false);
    };

    useEffect(() => {
        if (department_id > 0 || subjectId > 0) {
            getTeachers();
        }
    }, [department_id, subjectId]);

    return (
        <section className="ScrollLine overflow-hidden">
            {isLoading ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md  border-SlateBlue-300 dark:border-SlateBlue-800 p-10 bg-SlateBlue-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                        <LoadingIcon className="font-h1 text-didi" />
                        <span>يتم الآن تحميل المدرسين ...</span>
                    </div>
                </div>
            ) : teachers.length < 1 ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-didiRed-300 dark:border-didiRed-500 p-10 bg-didiRed-400 dark:bg-opacity-5 smooth clr-text-primary">
                        سيتم اضافة مدرسين داخل هذه الشبعة قريبًا
                    </div>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row-reverse space-y-5 items-center space-x-0 lg:space-x-5 justify-center ">
                    <button onClick={handleCarouselLeft} className=" px-4 py-2 rounded-md">
                        <img src={Arrow} alt="LeftArrow" className="rotate-180" />
                    </button>
                    <div
                        className="firstLineTeacher  overflow-hidden w-full md:max-w-[80%] mx-auto relative bg-[#F3F1F1] dark:bg-slate-800 smooth"
                        onMouseEnter={handleHover}
                        onMouseLeave={handleHover}
                    >
                        <div
                            className="inset-0 h-full w-full z-0 absolute opacity-20 dark:opacity-50 smooth"
                            style={{
                                backgroundImage: "url(" + pattern + ")",
                                backgroundSize: "contain",
                                backgroundPosition: "center center",
                                backgroundRepeat: "repeat-both",
                            }}
                        ></div>
                        <ParallaxTeacher baseVelocity={isHovering ? 0 : -5} moveX={moveX}>
                            <div className="lineTeacher">
                                {teachers.map((teacher, index) => (
                                    <Link to={`/teacher/${teacher.id}`} key={index}>
                                        <div className="p-6 bg-SlateBlue-200 rounded-[22px] dark:bg-slate-800 border border-SlateBlue-950 smooth">
                                            <div
                                                className="img-container rounded-[22px] border border-SlateBlue-950 shadow-lg"
                                                key={index}
                                            >
                                                <img
                                                    className="image"
                                                    src={`${baseURL}/${teacher.picture}`}
                                                    alt="test"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex clr-text-primary smooth flex-col space-y-2">
                                            {/* <div className="py-2">
                                                <div className="bg-yellow-500 rounded-md px-2 pb-4">
                                                </div>
                                            </div> */}
                                            <span className="text-center pt-4 text-xl font-rubik font-w-bold ">
                                                {teacher.name}
                                            </span>
                                            <span className="text-center max-w-[200px] mx-auto pb-7 text-xl text-wrap">
                                                {FormatDesc(teacher.description, true)}
                                            </span>
                                        </div>
                                    </Link>
                                ))}
                            </div>
                        </ParallaxTeacher>
                    </div>

                    {/* here div conatin left and right arrow to control it like slider  */}
                    <div className="mt-4">
                        <button onClick={handleCarouselRight} className=" px-4 py-2 rounded-md">
                            <img src={Arrow} alt="LeftArrow" className="" />
                        </button>
                    </div>
                </div>
            )}
        </section>
    );
}
