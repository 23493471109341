import React, { useContext, useState } from "react";
import {
    English,
    Arabic,
    France,
    italy,
    germany,
    Math,
    Geo,
    Bio,
    chemistry,
    Brain,
    book,
} from "./Svgs";
import { Link } from "react-router-dom";
import Button from "../../ui/Button";
import AuthContext from "../../../context/AuthContext";
import VariantButton from "../../ui/Buttons/VariantButton";
import { ReactComponent as Ellipse } from "../../../assets/Vip-imagery/Ellipse.svg";
import { isObjectEmpty } from "../../../utils/objects";
function SubjectCard({ first, sec, third, fourth, fifth, sixth }) {
    const [seeMoreState, setSeeMoreState] = useState({});

    const { token, user } = useContext(AuthContext);

    const toggleSeeMore = (index, set) => {
        setSeeMoreState((prevState) => ({
            ...prevState,
            [`${set}-${index}`]: !prevState[`${set}-${index}`],
        }));
    };

    const cardData1 = [
        {
            year: 5,
            title: "الصف الأول الإعدادي",
            data: fourth,
        },
        {
            year: 6,
            title: "الصف الثاني الإعدادي",

            data: fifth,
        },
        {
            year: 7,
            title: "الصف الثالث الإعدادي",

            data: sixth,
        },
    ];
    const cardData2 = [
        {
            year: 1,
            title: "الصف الأول الثانوي",
            data: first,
        },
        {
            year: 2,
            title: "الصف الثاني الثانوي",

            data: sec,
        },
        {
            year: 3,
            title: "الصف الثالث الثانوي",
            data: third,
        },
    ];

    return (
        <>
            <div className="w-full h-full py-20 flex flex-col space-y-20 relative z-10">
                {/* <div className="w-[1px] h-full md:h-[1px] bg-blueVip-950 dark:bg-blueVip-50 absolute top-0 bottom-0 mx-auto md:mx-0 md:left-0 md:right-0 md:top-1/2 " /> */}
                <div className="flex flex-wrap gap-10 justify-evenly">
                    {cardData1.map((card, index) =>
                        !token || user.year === card.year ? (
                            <div key={index}>
                                <div
                                    className={`min-h-[400px] w-[300px] rounded-xl overflow-hidden relative bg-[#F3F1F1] smooth dark:bg-slate-800 space-y-5  flex flex-col ${
                                        card.data.length && "justify-between"
                                    }  items-center px-2`}
                                >
                                    {/* <div className="w-[40px] min-h-[400px] bg-SlateBlue-900 smooth dark:bg-SlateBlue-400 z-0 -rotate-[-12.01deg] absolute -top-2 bottom-0 right-5 opacity-20 dark:opacity-30" /> */}

                                    <div className="flex flex-col space-y-10">
                                        <div className="">
                                            <h1 className="font-rubik pt-5 text-center text-2xl clr-text-primary smooth  relative z-10">
                                                {card.title}
                                            </h1>
                                        </div>

                                        <div className=" w-full m-auto h-full px-3 py-6 p-3 flex gap-2 justify-evenly items-center flex-col">
                                            {card.data
                                                .slice(
                                                    0,
                                                    seeMoreState[`${"set1"}-${index}`]
                                                        ? card.data.length
                                                        : 3
                                                )
                                                .map((item, index) => {
                                                    return (
                                                        <Link
                                                            to={`/subject/${item[1]}`}
                                                            key={index}
                                                        >
                                                            <p
                                                                className="text-4xl z-10 relative smooth hover:bg-didiBlue bg-didiBlue clr-white rounded-2xl px-5 py-2 font-h3 font-bold text-center"
                                                                key={index}
                                                            >
                                                                {item[0]}
                                                            </p>
                                                        </Link>
                                                    );
                                                })}
                                        </div>
                                    </div>

                                    {card.data.length ? (
                                        <div className="relative">
                                            <VariantButton
                                                ButtonType="Retro"
                                                hoverEffect=""
                                                className=" hover:bg-didiBlue bg-didiBlue clr-white h-[47px] w-full rounded-t-md rounded-tr-md relative z-10 smooth font-w-bold  mx-auto"
                                                onClick={() => toggleSeeMore(index, "set1")}
                                            >
                                                {seeMoreState[`${"set1"}-${index}`]
                                                    ? "اخفاء"
                                                    : "عرض المزيد"}
                                            </VariantButton>
                                        </div>
                                    ) : (
                                        <p className="w-full px-4 text-center bg-didiBlue smooth clr-white relative z-10 rounded-md py-4 text-xl mx-auto">
                                            سيتم توافر تخصصات للسنة الدراسية قريبا
                                        </p>
                                    )}
                                </div>
                            </div>
                        ) : null
                    )}
                </div>
                <div className="flex flex-wrap gap-10 justify-evenly">
                    {cardData2.map((card, index) => (
                        <div key={index}>
                            <div
                                className={`min-h-[400px] w-[300px] rounded-xl overflow-hidden relative bg-[#F3F1F1] smooth dark:bg-slate-800 space-y-5  flex flex-col ${
                                    card.data.length && "justify-between"
                                }  items-center px-2`}
                            >
                                {/* <div className="w-[40px] min-h-[400px] bg-SlateBlue-900 smooth dark:bg-SlateBlue-400 z-0 -rotate-[-12.01deg] absolute -top-2 bottom-0 right-5 opacity-20 dark:opacity-30" /> */}
                                <div className="flex flex-col space-y-10">
                                    <div className="  pt-5">
                                        <h1 className="font-rubik text-center text-2xl clr-text-primary smooth relative z-10">
                                            {card.title}
                                        </h1>
                                    </div>

                                    <div className=" w-full m-auto h-full px-3 py-6 p-3 flex gap-2 justify-evenly items-center flex-col">
                                        {card.data
                                            .slice(
                                                0,
                                                seeMoreState[`${"set2"}-${index}`]
                                                    ? card.data.length
                                                    : 3
                                            )
                                            .map((item, index) => {
                                                return (
                                                    <Link to={`/subject/${item[1]}`} key={index}>
                                                        <p
                                                            className="text-4xl z-10 relative smooth hover:bg-didiBlue bg-didiBlue clr-white rounded-2xl px-5 py-2 font-h3 font-bold text-center"
                                                            key={index}
                                                        >
                                                            {item[0]}
                                                        </p>
                                                    </Link>
                                                );
                                            })}
                                    </div>
                                </div>

                                {card.data.length ? (
                                    <div className="relative">
                                        <VariantButton
                                            ButtonType="Retro"
                                            hoverEffect=""
                                            className=" hover:bg-didiBlue bg-didiBlue clr-white h-[47px] w-full rounded-t-md rounded-tr-md relative z-10 smooth font-w-bold  mx-auto"
                                            onClick={() => toggleSeeMore(index, "set2")}
                                        >
                                            {seeMoreState[`${"set2"}-${index}`]
                                                ? "اخفاء"
                                                : "عرض المزيد"}
                                        </VariantButton>
                                    </div>
                                ) : (
                                    <p className="w-full px-4 text-center bg-didiBlue smooth clr-white relative z-10 rounded-md py-4 text-xl mx-auto">
                                        سيتم توافر تخصصات للسنة الدراسية قريبا
                                    </p>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
}

export default SubjectCard;
