import React from "react";
import TextBrandName from "../../assets/didi-imagery/text-brandname.png";
export const SectionHeader = ({
    title,
    SpecialFont = false,
    className,
    position = null,
    showSlogan = false,
}) => {
    return (
        <div
            className={`z-10 relative font-bold ${SpecialFont && "font-rady"} ${className} smooth`}
        >
            {"   "}
            {title}{" "}
            {showSlogan && (
                <span
                    className={`vipFont text-didiBlue/50 absolute ${
                        position ? position : "right-0 -top-10 -z-10 left-0"
                    } opacity-40 w-[250px] md:w-[600px] text-[36px] md:text-[90px]`}
                >
                    PEN-DIDI
                </span>
            )}
            {/* <img
                src={TextBrandName}
                className={`absolute min-w-[230px] sm:min-w-[300px] md:min-w-[470px] ${position}`}
                alt="PEN-DIDI"
            /> */}
        </div>
    );
};
