import React, { useState } from "react";
import Electric from "../../components/svgs/Electric";

import "./MainSection.css";

import Button from "../../components/ui/Button";
import auth from "../../services/authServices";
// import FlexRowReverse from "../../components/ui/FlexRowReverse";
import Container from "../../components/ui/Container";
import NewProfile from "../../assets/Updated-imagery/New-Profile-hero.svg";
import starsbg from "../../assets/starsbg.png";
import bgbottomleft from "../../assets/bgbottomleft.svg";
import TeacherSlider from "./TeacherSlider";
import BookIcon from "../../assets/Vip-imagery/Book.png";
import VariantButton from "../../components/ui/Buttons/VariantButton";
// import HeroImage from "../../assets/didi-imagery/HeroDidiImg.png";
import HeroImage from "../../assets/didi-imagery/logoHero.png";
import "./Heroimage.css";
import TextBrandName from "../../assets/didi-imagery/text-brandname.png";
import "../../components/ui/Buttons/Btns.css";
import { Link } from "react-router-dom";
import CenterIcon from "../../components/ui/CenterIcon";
import mentor from "../../assets/didi-imagery/mentor.svg";
import { SectionHeader } from "../../components/ui/SectionHeader";
const MainSection = ({ title = false }) => {
    const token = auth.getToken();

    // const buttonColorClass = token ? {} : { color: "rose" };
    const scrollToMentors = () => {
        const element = document.getElementById("mentors");
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <section className="md:h-screen min-h-screen relative overflow-hidden flex md:items-center md:justify-center md:mb-[10rem] home-main_section">
                <Container className="h-auto relative z-10  w-full">
                    <div className="w-full flex md:flex-row flex-col h-full justify-evenly md:h-screen space-y-15 md:space-y-0 relative z-10 items-center">
                        <div className="md:basis-1/2 basis-full h-full flex-center-both pt-20 md:pt-0">
                            <div className="max-w-sm relative h-full flex-center-both md:max-w-lg">
                                <img
                                    src={HeroImage}
                                    alt={"profile-updated-svg"}
                                    className="w-full h-auto"
                                />
                                {/* <div className="absolute bg-primary-container dark:opacity-20 opacity-0 smooth inset-0" /> */}
                            </div>
                        </div>
                        <div className="  md:basis-1/2 basis-full h-full flex items-center ">
                            <div className="flex-col md:space-y-12 space-y-6 text-center lg:text-right">
                                <div className="flex flex-col space-y-8">
                                    <div className="flex justify-center lg:justify-start ">
                                        <h1 className="font-w-bold relative smooth  text-4xl md:text-6xl xl:text-7xl">
                                            <SectionHeader
                                                title="دي دي"
                                                SpecialFont
                                                position={
                                                    "md:right-0 -top-10 -z-10 !hidden md:block md:left-0"
                                                }
                                                className="text-didiBlue"
                                                showSlogan={true}
                                            />
                                        </h1>
                                    </div>
                                    {!title && (
                                        <h2 className="font-h1 font-semibold font-rubik ">
                                            طور قدراتك، التعليم أصبح أسهل
                                            <br />
                                            وأمتع معنا{" "}
                                        </h2>
                                    )}
                                </div>
                                {!title && (
                                    <div className="btn-holder flex-center-both clr-white md:flex-row flex-col space-y-8 md:space-y-0 space-x-0 md:space-x-10 md:space-x-reverse">
                                        <Link
                                            className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-SlateBlue-500 before:border-t-SlateBlue-500 after:border-r-SlateBlue-500 after:border-b-SlateBlue-500 "
                                            to={`${token ? "/me/user/courses" : "/register"}`}
                                        >
                                            <div className="bg-didiBlue smooth px-5 py-4 flex-center-both gap-4">
                                                <span className="text-link  text-lg">
                                                    {token ? <>كورساتي </> : <>أشترك دلوقتي</>}
                                                </span>
                                                <CenterIcon
                                                    icon="majesticons:login-half-circle"
                                                    className=" font-h2 smooth"
                                                />
                                            </div>
                                        </Link>
                                        <button
                                            className="btn btn-3 hover-border-2 flex-row-reverse before:border-l-[1px] before:border-b-[1px] after:border-r-[1px] after:border-t-[1px] before:border-l-SlateBlue-500 before:border-b-SlateBlue-500 after:border-r-SlateBlue-500 after:border-t-SlateBlue-500 "
                                            onClick={scrollToMentors}
                                        >
                                            <div className="bg-didiBlue smooth px-5 py-4 flex-center-both gap-4">
                                                <img src={mentor} alt="mentoricon" />
                                                <span className="text-link text-lg">
                                                    اكتشف الخبراء
                                                </span>
                                            </div>
                                        </button>
                                    </div>
                                )}

                                {title ? (
                                    <>
                                        <div className="font-h1 font-semibold font-rubik ">
                                            {title}
                                        </div>
                                        <div className="btn-holder clr-white ">
                                            <Link
                                                className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-SlateBlue-500 before:border-t-SlateBlue-500 after:border-r-SlateBlue-500 after:border-b-SlateBlue-500 "
                                                to="/"
                                            >
                                                <div className="bg-didiBlue smooth px-5 py-4 flex-center-both gap-4">
                                                    <span className="text-link  text-lg">
                                                        الذهاب للصفحة الرئيسية
                                                    </span>
                                                    <CenterIcon
                                                        icon="majesticons:login-half-circle"
                                                        className=" font-h2 smooth"
                                                    />
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    );
};

export default MainSection;
