import React, { useEffect, useState } from "react";

import http from "../../services/httpServices";
import LoadingIcon from "../../components/ui/LoadingIcon";
import { baseURL } from "../../config";

const MentorGrid = () => {
    const [mentors, setMentors] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const fetchMentors = async () => {
        let params = {
            limit: 100,
        };
        try {
            const { data: res } = await http.get("/api/teacher", {
                params,
            });
            setMentors(res.results);
            setIsLoading(false);
        } catch (error) {
            console.error("Failed to fetch mentors:", error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchMentors();
    }, []);

    return (
        <section className="relative mb-10 overflow-hidden" id="mentors">
            {isLoading ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md  border-SlateBlue-300 dark:border-SlateBlue-800 p-10 bg-SlateBlue-100 dark:bg-opacity-5 smooth clr-text-primary flex space-x-2 space-x-reverse">
                        <LoadingIcon className="font-h1 text-SlateBlue-500" />
                        <span>يتم الآن تحميل المدرسين ...</span>
                    </div>
                </div>
            ) : mentors.length < 1 ? (
                <div className="flex-center-both">
                    <div className="border-2 rounded-md border-didiRed-300 dark:border-didiRed-500 p-10 bg-didiRed-400 dark:bg-opacity-5 smooth clr-text-primary">
                        سيتم اضافة مدرسين داخل هذه الشبعة قريبًا
                    </div>
                </div>
            ) : (
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 place-content-center">
                    {mentors.map((mentor) => (
                        <div
                            key={mentor.id}
                            className="flex flex-col h-[310px] items-center text-center"
                        >
                            <img
                                src={`${baseURL}/${mentor.picture}`}
                                alt={mentor.name}
                                className="w-[140px] h-[140px] rounded-full object-cover"
                            />
                            <h3 className="mt-4 text-lg font-semibold">{mentor.name}</h3>
                            <p className="text-sm text-gray-600">{mentor.description}</p>
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
};

export default MentorGrid;
