import React from "react";

import { ScrollingProvider } from "../context/ScrollingContext";

import MainSection from "../sections/home/MainSection";
// import AboutSection from "../sections/home/AboutSection";
import SubjectsSection from "../sections/home/CoursesSection/SubjectsSection";
import auth from "../services/authServices";
import LoggedInHome from "../sections/home/LoggedInHome";
import TeacherSection from "../sections/home/TeacherSection";
import FeatureSection from "../sections/home/FeatureSection";
import CtaStudent from "../sections/home/CtaStudent";
import CtaTeachers from "../sections/home/CtaTeachers";
import BookSection from "../sections/home/BookSection";
import AboutSection from "../sections/home/AboutSection";
import "../components/ui/Buttons/Btns.css";
import CTASectionMentors from "../sections/home/CTASectionMentors";
import MentorGrid from "../sections/home/MentorGrid";
const Mentors = () => {
    const token = auth.getToken();
    return (
        <>
            <MainSection title={"تعرف علي نخبة من خبراؤنا"} />

            <section
                className="relative flex flex-col space-y-10 mx-[2rem] sm:mx-[4rem] md:mx-[7rem] mb-10 mt-10 overflow-hidden"
                id="mentors"
            >
                <h1 className="text-4xl text-center mr-0 md:mr-10  md:text-right md:text-7xl">
                    جميع خبراؤنا ؟
                </h1>
                <div className="pt-10">
                    <MentorGrid />
                </div>
                <CTASectionMentors />
            </section>
            {/* <BookSection /> */}
        </>
    );
};

export default Mentors;
