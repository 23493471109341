import React, { useContext, useEffect, useState } from "react";
import InputField from "../../../components/form/elements/InputField";
import { handleInputChange } from "../../../services/formServices";
import { isMultiYear } from "../../../services/defaultSettings";
import { years } from "../../../services/yearSevices";
import Container from "../../../components/ui/Container";
import auth from "../../../services/authServices";
import http from "../../../services/httpServices";
import AuthContext from "../../../context/AuthContext";
import { isEmptyObject } from "jquery";

import Heading from "../../../components/NewUi/heading/Heading";
import TeacherLine from "../../../components/NewUi/teacherHorizontal/TeacherLine";

import { SectionHeader } from "../../../components/ui/SectionHeader";
import { ModernCardSection } from "../../../components/ui/CardSectionLibrary";

// import { ScrollingProvider } from "../../../context/ScrollingContext";
// import SectionHead from "../../../components/ui/SectionHead";
// import SubjectsGrid from "./SubjectsGrid";
// import TeachersGrid from "./TeachersGrid";
// import CoursesGrid from "./CoursesGrid";
// import SideTitle from "../../../components/ui/SideTitle";
// import Categorie from "./Categorie";
// import { values } from "lodash";

const SubjectsSection = () => {
    const [filterData, setFilterData] = useState({
        year: 3,
        department_id: 0,
    });
    const [departments, setDepartments] = useState([]);
    const [categorieApi, setCategorieApi] = useState("");

    const { user } = useContext(AuthContext);
    const [first, setFirst] = useState([]);
    const [second, setSecond] = useState([]);
    const [third, setThird] = useState([]);
    const toUseYears = years.filter((value, index) => index < 3);
    const [subjectData, setSubjectData] = useState({
        first: [],
        sec: [],
        third: [],
        fourth: [],
        fifth: [],
        sixth: [],
    });

    const getSubjects = async () => {
        const { data } = await http.get(`api/with_year_id/subjects/options`);
        // console.log("API Response:", data);

        const years = ["1", "2", "3", "4", "5", "6"];

        const newSubjectData = years.reduce((acc, year) => {
            acc[year] = data
                .filter((subject) => subject.year === year)
                .map((item) => [item.text, item.value]);
            return acc;
        }, {});

        setSubjectData({
            first: newSubjectData["1"],
            sec: newSubjectData["2"],
            third: newSubjectData["3"],
            fourth: newSubjectData["4"],
            fifth: newSubjectData["5"],
            sixth: newSubjectData["6"],
        });
    };

    const getDepartments = async () => {
        const token = auth.getToken();
        const config = auth.getAuthConfig(token);
        const { data } = await http.get(`api/years/${filterData.year}/departments/options`, config);
        // console.log(data);
        // console.log(filterData)
        setDepartments([]);
        if (data.length < 1) {
            setFilterData({ ...filterData, department_id: 0 });
        } else {
            setFilterData({ ...filterData, department_id: data[0]["value"] });
        }
        setDepartments(data);
        // console.log(filterData.department_id);
    };
    // console.log(departments)
    useEffect(() => {
        getSubjects();
        getDepartments();
    }, [filterData.year]);

    useEffect(() => {
        getSubjects();
    }, []);

    useEffect(() => {
        if (!isEmptyObject(user) && user.year && user.year != filterData.year) {
            setFilterData({
                ...filterData,
                year: user.year,
            });
        }
    }, [user]);

    useEffect(() => {
        if (filterData.department_id > 0) {
            setCategorieApi(`/api/departments/${filterData.department_id}/courses`);
        }
    }, [filterData.department_id]);

    // useEffect(() => {
    //     console.log("Updated subjectData:", subjectData);
    // }, [subjectData]);

    return (
        <div className=" mb-20 mt-10 " id="courses">
            {/* <ScrollingProvider>
                <SectionHead title={"محتوى المنصة"} /> 
            </ScrollingProvider> */}
            <div className="">
                <Container className="flex relative flex-col space-y-10 items-center justify-center">
                    <div className="flex flex-col space-y-20 items-center">
                        <div className="pt-10">
                            <SectionHeader
                                title="تخصصات"
                                SpecialFont
                                className="text-4xl md:text-6xl xl:text-7xl clr-text-primary smooth "
                                position="-right-8 -top-10  sm:-top-16 -z-10 md:left-5"
                            />
                        </div>
                    </div>
                    <ModernCardSection isSubject subjectData={subjectData} />
                </Container>

                <Container className="my-[10rem]">
                    <Container className="flex flex-col space-y-10 items-center justify-center">
                        <div className="flex flex-col space-y-20 items-center">
                            <div className="pt-10">
                                <SectionHeader
                                    title="أختر بنفسك"
                                    SpecialFont
                                    className="text-4xl md:text-6xl xl:text-7xl"
                                    position="-right-8 -top-0  sm:-top-10 -z-10 md:left-5"
                                />
                            </div>
                        </div>
                    </Container>

                    <div
                        className={
                            isEmptyObject(user) || !user.year
                                ? "flex-center-both"
                                : "flex items-center justify-center"
                        }
                    >
                        <div
                            className={`flex items-center justify-between  md:flex-row flex-col ${
                                isEmptyObject(user) || !user.year
                                    ? "md:space-x-20 md:space-x-reverse space-y-4 md:space-y-0"
                                    : "space-x-0"
                            }  `}
                        >
                            <div
                                className={` px-10 py-3 rounded-md ${
                                    isEmptyObject(user) || !user.year
                                        ? "border border-solid border-gray-400"
                                        : "hidden"
                                }  smooth clr-text-primary`}
                            >
                                {isMultiYear && (isEmptyObject(user) || !user.year) ? (
                                    <InputField
                                        onChange={handleInputChange}
                                        data={filterData}
                                        setData={setFilterData}
                                        type="select"
                                        options={toUseYears}
                                        className="min-w-[250px]"
                                        id="year"
                                        placeholder="اختر الصف الدراسي"
                                    />
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className=" px-10 py-3 rounded-md  border border-solid border-gray-400 smooth clr-text-primary">
                                <InputField
                                    onChange={handleInputChange}
                                    data={filterData}
                                    setData={setFilterData}
                                    type="select"
                                    className="min-w-[250px]"
                                    options={departments}
                                    id="department_id"
                                    placeholder="اختر الشعبة"
                                />
                            </div>
                        </div>
                    </div>
                </Container>
                <div>
                    {filterData.department_id === 0 ? (
                        <Container>
                            <div className="flex-center-both">
                                <div className="border-2 rounded-md border-yellow-300 dark:border-yellow-500 p-10 bg-yellow-100 dark:opacity-5 smooth clr-text-primary">
                                    اختر الشعبة الدراسية اولًا لإختيار المادة !
                                </div>
                            </div>
                        </Container>
                    ) : (
                        <>
                            <div className="relative">
                                {" "}
                                <TeacherLine department_id={filterData.department_id} />{" "}
                            </div>
                            {/* <SubjectsGrid department_id={filterData.department_id} /> */}
                            {/* <TeachersGrid department_id={filterData.department_id} /> */}
                            {/* <Categorie
                                titleFirst="كورسات"
                                titleLast="الشعبة"
                                noCoursesPlaceholder={"سيتم اضافة الكورسات داخل هذه الشبعة قريبًا"}
                                api={categorieApi}
                            /> */}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubjectsSection;
