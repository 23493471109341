import React from "react";
// import { ReactComponent as TextLogo } from "../../assets/didi-imagery/text-brandname.svg";
import TextLogo from "../../assets/didi-imagery/text-brandname.png";
import { Link } from "react-router-dom";
import Button from "../../components/ui/Button";
import modal from "../../services/modalServices";

const CTASectionMentors = () => {
    return (
        <section className="min-h-[50vh] relative my-10 mx-2 md:mx-10 rounded-[44px] md:h-fit flex md:flex-row flex-col overflow-hidden flex-center-both bg-SlateBlue-100 dark:bg-[#374866] border border-black smooth ">
            <div className="w-full relative z-10 flex-center-both space-y-10 flex-col ">
                <div className="flex-center-both flex-col space-y-7">
                    <h1 className="text-3xl md:text-5xl clr-text-primary smooth font-w-bold space-y-5 flex-col flex-center-both">
                        <span>عايز تنضم لرحلتنا</span>

                        <span className="text-didiBlue">كـ مٌعلم !</span>
                    </h1>

                    <p className="text-xl md:text-3xl text-center clr-text-primary smooth">
                        بسهولة تقدر تشارك في رسالتنا
                        <br />
                        وتبقي واحد من عيلة .. دي دي
                    </p>
                </div>
                <div className="btn-holder flex justify-center md:justify-start">
                    <Button
                        className="btn btn-3 hover-border-1 before:border-l-[1px] before:border-t-[1px] after:border-r-[1px] after:border-b-[1px] before:border-l-didiBlue before:border-t-didiBlue after:border-r-didiBlue after:border-b-didiBlue "
                        to={`/`}
                        color="didiBlue"
                        onClick={() => {
                            modal.message({
                                title: "شوية وجايين",
                                text: "سيتم وضع وسيلة تواصل لنا قريب أوي",
                                icon: "warning",
                            });
                        }}
                    >
                        <div className="bg-didiBlue smooth px-10 py-3 flex-center-both gap-4">
                            <span className="text-link clr-white text-lg">أنضم لينا </span>
                        </div>
                    </Button>
                </div>
            </div>
            <div className="w-[440.74px] min-h-[561.47px] bg-didiBlue smooth opacity-20 -rotate-[-12.01deg] absolute -top-20 bottom-0 -left-20" />
            <div className="w-[440.74px] min-h-[561.47px] bg-didiBlue smooth opacity-20 -rotate-[-12.01deg] absolute -top-10 bottom-0 -right-20" />
            {/* <TextLogo className="w-[320px] md:w-auto absolute bottom-5 left-3" /> */}
            <img src={TextLogo} alt="" className="w-[320px] md:w-auto absolute bottom-5 left-3" />
        </section>
    );
};

export default CTASectionMentors;
