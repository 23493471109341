// import React from 'react';
import { ReactComponent as Ellipse } from "../../../assets/Vip-imagery/Ellipse.svg";
const cardData = [
    {
        title: "مع كل الفئات",
        description:
            "منصة تشمل جميع طلبة الجمهوية من إعدادي حتي ثانوي ومنسناش طلبة اللغات وكمان ولادنا طلبة الأزهر",
    },
    {
        title: "ابدع وابتسط",
        description:
            "منصة وفرتلك dashboard تقدر منها تتابع كل محتواك ودرجات كويزاتك وأشتراكاتك ومميزات تانيه كتيييييير",
    },
    {
        title: "متابعة مستمرة",
        description:
            "متخافش دي بس عشان نتابع مستواك ونخليك مش مراكم حاجه وراك وننبهك عشان نضمنلك التفوق والتميز عن غيرك",
    },
];

const Card = () => {
    return (
        <div className="w-full h-full flex flex-wrap justify-evenly gap-6 md:gap-3 relative z-10">
            {/* <div className="w-[1px] h-full md:h-[1px] bg-blueVip-950 dark:bg-blueVip-50 absolute top-0 bottom-0 mx-auto md:mx-0 md:left-0 md:right-0 md:top-1/2 " /> */}
            {cardData.map((card, index) => (
                <div key={index} className={`${index == 1 && "mt-0 md:mt-10"}`}>
                    <div className="h-[282px]  w-[300px] relative bg-blueVip-300 smooth dark:bg-blueVip-800 border border-solid border-black flex flex-col space-y-10 items-center px-2 py-5">
                        <Ellipse className="fill-blueVip-100 dark:fill-blue-600 smooth absolute top-0" />
                        <div className="relative">
                            <h1 className="font-expo text-center text-2xl text-blueVip-950 dark:text-blueVip-50 relative z-10">
                                {card.title}
                            </h1>
                            <div className="absolute inset-0"></div>
                        </div>
                        <div className="">
                            <p className="text-blueVip-950 text-center dark:text-blueVip-50 text-lg">
                                {card.description}
                            </p>
                        </div>
                        <div className="w-20 h-3 bg-Lemon-200 dark:bg-Lemon-500 absolute bottom-0 mx-auto " />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Card;
